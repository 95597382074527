import { ActionButton } from "@able/react";
import SearchCombobox2 from "components/SearchCombobox2";
import { PageHeading } from "components/ui/PageHeading";
import Fuse, { Expression, IFuseOptions } from "fuse.js";
import { useNotificationRecipients } from "hooks/useNotificationRecipients";
import { useTenancyData } from "hooks/useTenancyData";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useBreadCrumbs } from "services/hooks/useBreadcrumbs";
import { NotificationRecipient } from "types";
import { NotificationRecipientTable } from "./NotificationRecipientTable";
import "./styles.scss";

function filterData<T>(data: object[], searchPattern: string | Expression, fuseOptions?: IFuseOptions<object>): T {
  if (!data) return [] as T;

  if (!searchPattern && data) return data as T;

  const fuse = new Fuse(data, fuseOptions);
  const results = fuse.search(searchPattern).map((data) => data.item);
  return results as T;
}

function NotificationManagement() {
  const { data: tenancyData } = useTenancyData();
  const { data: recipientData, isLoading: loadingRecipients } = useNotificationRecipients(tenancyData?.[0].tenancyId);
  const [filter, setFilter] = useState<string>("");

  useBreadCrumbs([{ label: "Dashboard", to: "/" }]);

  // Derive the filter data.
  const filteredData = filterData<NotificationRecipient[]>(recipientData, filter, {
    includeScore: true,
    useExtendedSearch: true,
    keys: ["name", "email", "mobileNumber"],
    threshold: 0.1,
  });

  return (
    <main>
      <Helmet>
        <title>Notification Management - Telstra Dedicated Network</title>
      </Helmet>
      <PageHeading content="Notification Management" />
      <div className="table-controls">
        <SearchCombobox2
          title="Filter Recipients"
          options={filteredData.map(({ name, recipientId }) => ({ displayText: name, itemId: recipientId }))}
          onFieldChange={setFilter}
          helperText="The table will be filtered as you type."
        />
        <ActionButton
          className="recipient-button"
          developmentUrl="/able-sprites.svg"
          element="Link"
          label="Create a new recipient"
          to="/notification-management/create"
          variant="MediumEmphasis"
        />
      </div>
      <NotificationRecipientTable isLoading={loadingRecipients} data={filteredData} />
    </main>
  );
}

export default NotificationManagement;
