import { useNavigatedOutside } from "hooks/useNavigatedOutside";
import { KeyboardEvent, useRef, useState } from "react";
import "./styles.scss";

type Options = {
  displayText: string;
  itemId: string | number;
};

type Props = {
  title: string;
  helperText: string;
  options: Array<Options>;
  onFieldChange: (value: string) => void;
};

// TODO: Uplift this component
function SearchCombobox2({ title, helperText, options, onFieldChange }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const listRef = useRef<HTMLUListElement>(null);

  // @ts-expect-error
  const comboboxRef = useNavigatedOutside({ callback: () => setOpen(false), listenClicks: true });
  // @ts-expect-error
  const inputRef = useNavigatedOutside({ callback: () => setOpen(false), listenTabs: true });

  function openOptions() {
    if (value !== "" && !open) setOpen(true);
  }

  function clearSearch() {
    const value = "";
    setOpen(false);
    setValue(value);
    onFieldChange(value);
    // @ts-expect-error
    if (inputRef && inputRef.current) inputRef.current.focus();
  }

  function inputOnChange(value: string) {
    if (value !== "") {
      setOpen(true);
    }
    setValue(value);
    onFieldChange(value);
  }

  /**
   * Matches the letters typed into the input with the matched result and
   * adds bold styling to the matches letters typed in search. This is to
   * align with Telstra UI Enterprise Search component styling.
   *
   * Ben Nicholls wrote this magic, reach out to him for questions.
   *
   * @param {string} text the content in list element
   * @returns styled text
   */
  const styleText = ({ text }: { text: string }) => {
    const matchInput = text;
    const textEntry = value;

    // Normalise entries
    const lowerCaseMatch = matchInput.toString().toLowerCase();
    const lowerCaseEntry = textEntry.toString().toLowerCase();
    const startIndex = lowerCaseMatch.indexOf(lowerCaseEntry);
    if (startIndex !== -1) {
      const endIndex = startIndex + textEntry.length;
      const prefix = matchInput.substring(0, startIndex);
      const bold = matchInput.substring(startIndex, endIndex);
      const suffix = matchInput.substring(endIndex);

      return (
        <>
          {prefix}
          <b>{bold}</b>
          {suffix}
        </>
      );
    } else {
      return <>{text}</>;
    }
  };

  const upArrowControls = ({
    event,
  }: {
    event: KeyboardEvent<HTMLInputElement | HTMLUListElement | HTMLLIElement>;
  }) => {
    if (options.length > 0) {
      event.preventDefault();
      if (listRef.current) {
        if (currentIndex === null) {
          setCurrentIndex(listRef.current.children.length - 1);
          // @ts-expect-error
          listRef.current.children[listRef.current.children.length - 1].focus();
        } else if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
          // @ts-expect-error
          listRef.current.children[currentIndex - 1].focus();
        } else if (currentIndex === 0) {
          setCurrentIndex(listRef.current.children.length - 1);
          // @ts-expect-error
          listRef.current.children[listRef.current.children.length - 1].focus();
        }
      }
    }
  };

  const downArrowControls = ({
    event,
  }: {
    event: KeyboardEvent<HTMLInputElement | HTMLUListElement | HTMLLIElement>;
  }) => {
    if (options.length > 0) {
      event.preventDefault();
      if (listRef.current) {
        if (currentIndex === null) {
          setCurrentIndex(0);
          // @ts-expect-error
          listRef.current.children[0].focus();
        } else if (currentIndex < listRef.current.children.length - 1) {
          setCurrentIndex(currentIndex + 1);
          // @ts-expect-error
          listRef.current.children[currentIndex + 1].focus();
        } else if (currentIndex === listRef.current.children.length - 1) {
          setCurrentIndex(0);
          // @ts-expect-error
          listRef.current.children[0].focus();
        }
      }
    }
  };

  const keyDownHandler = ({
    event,
    value,
    element,
  }: {
    event: KeyboardEvent<HTMLInputElement | HTMLUListElement | HTMLLIElement>;
    value?: string;
    element?: string;
  }) => {
    if (!event) return;
    if (
      element === "ul" &&
      ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90))
    ) {
      // @ts-expect-error
      inputRef.current.focus();
      setCurrentIndex(-1);
      return;
    }
    switch (event.key) {
      case "Enter":
        if (value) {
          setValue(value);
        }
        setOpen(false);
        // @ts-expect-error
        inputRef.current.focus();
        break;

      case "Down":
      case "ArrowDown":
        openOptions();
        downArrowControls({ event });
        break;

      case "Up":
      case "ArrowUp":
        openOptions();
        upArrowControls({ event });
        break;

      case "Esc":
      case "Escape":
        if (open) {
          setOpen(false);
        }
        break;

      case "Tab":
        if (element === "li") {
          if (value) setValue(value);
          setOpen(false);
        }
        break;
      case "Backspace":
        if (element === "ul" || element === "li") {
          // @ts-expect-error
          inputRef.current.focus();
          setCurrentIndex(-1);
        }
        break;
      default:
        break;
    }
  };

  return (
    // @ts-expect-error
    <div ref={comboboxRef} className="container">
      <label htmlFor="search-input">{title}</label>
      {helperText && <span id="search-helper-text">{helperText}</span>}
      <div className="combobox">
        <div className="group">
          <input
            id="search-input"
            // @ts-expect-error
            ref={inputRef}
            type="text"
            role="combobox"
            aria-describedby="search-helper-text"
            aria-autocomplete="list"
            aria-expanded={options.length > 0 ? true : false}
            aria-controls="search-options"
            placeholder="Search"
            value={value}
            onChange={(e) => inputOnChange(e.target.value)}
            onKeyDown={(event) => keyDownHandler({ event })}
            onFocus={() => {
              if (value !== "") setOpen(true);
            }}
            onClick={() => {
              openOptions();
              setCurrentIndex(-1);
            }}
          />

          {value !== "" && (
            <button className={`${value !== "" ? "show" : "hide"}`} onClick={clearSearch}>
              <svg
                className={`able-icon able-icon--24`}
                role="img"
                aria-hidden="false"
                aria-label="Clear search"
                focusable="false"
              >
                <use href="/assets/able-sprites.svg#Close"></use>
              </svg>
            </button>
          )}
          <div className="search-icon-container">
            <svg
              className="able-icon able-icon--24"
              role="img"
              aria-hidden="false"
              aria-label="Search"
              focusable="false"
            >
              <use href="/assets/able-sprites.svg#Search"></use>
            </svg>
          </div>
        </div>
        {open && (
          <ul
            ref={listRef}
            id="search-options"
            role="listbox"
            aria-label="Services"
            onKeyDown={(event) => keyDownHandler({ event, element: "ul" })}
          >
            {options.length ? (
              options.map((e, idx) => (
                <li
                  role="option"
                  key={idx}
                  tabIndex={idx === currentIndex ? 0 : -1}
                  aria-selected={idx === currentIndex}
                  onKeyDown={(event) => keyDownHandler({ event, value: e.displayText, element: "li" })}
                  onClick={() => {
                    setValue(e.displayText);
                    setOpen(false);
                    // @ts-expect-error
                    inputRef.current.focus();
                  }}
                >
                  {styleText({ text: e.displayText })}
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}

export default SearchCombobox2;
